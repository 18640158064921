import RepositoryBase, {IFindOption} from "@/repositories/repository-base";

import {CompanyClientEntity} from "@/entities/company-client-entity";

export class CompanyClientRepository extends RepositoryBase<CompanyClientEntity> {
  protected ctor:new(data) => CompanyClientEntity = CompanyClientEntity;

  protected companyId!:number;

  public constructor(companyId) {
    super();
    this.companyId = companyId;
    this.endpoint = `companies/${companyId}/company_clients`
  }

  public async all(): Promise<CompanyClientEntity[]>  {
    return this.findAll();
  }
  public async listSubmitter(): Promise<{id:number, uid:string, name: string}[]>  {
    const res = await super.get(this.endpoint + '/list_submitter');
    return res.data;
  }

  public async findCompanyClient(id:number): Promise<CompanyClientEntity>  {
    return this.findById(id);
  }

  public async updateCompanyClient(id:number, data: Partial<CompanyClientEntity>) {
    return this.update(id, data);
  }
}
