import ObjectUtils from "@/utils/object-utils";
import {PartnerType, PartnerTypeDict} from "@/entities/specs/partner-entity";
import {CompanyBasicEntity} from "@/entities/company-entity";
import {createMaxStringRule, getTypeIntRule} from "@/utils/validation-rules";

export class CompanyClientEntity {
  public id!:number;
  public client!:CompanyBasicEntity;
  public isSubmitter!:boolean;
  public type!:PartnerType|null;

  public clientOperatorName!:string|null;
  public establishmentYear!:number|null;
  public annualSales!:number|null;
  public employeesCount!:number|null;
  public note!:string|null;
  public hiddenSpecFields!:string[]|null;

  constructor(init:Partial<CompanyClientEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if(init.client) {
      this.client = new CompanyBasicEntity(init.client);
    }
    if (init.hiddenSpecFields) {
      this.hiddenSpecFields = init.hiddenSpecFields;
    }
  }

  public getTypeName():string {
    if (!this.type) return '未分類';
    return PartnerTypeDict[this.type];
  }
}


export const ValidatorRules:any = {
  clientOperatorName: [createMaxStringRule()],
  annualSales: [getTypeIntRule()],
  employeesCount: [getTypeIntRule()],
};
